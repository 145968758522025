import React from "react"
import Base from "../../components/templates/Base"

import LoginForm from "../../components/Login/Login"
import styled from "styled-components"
import ThemeDefault from "../../components/theme/ThemeDefault"
import device from "../../components/theme/MediaQueries"
import Tick from "../../assets/images/icons/tick.svg";
import Point from "../../assets/images/price-stand-point.svg"
import { Link } from "gatsby"

import sectionImage1 from "../../images/section-1.png"
import sectionImage2 from "../../images/section-2.png"
import sectionImage3 from "../../images/section-3.png"
import sectionImage4 from "../../images/section-4.png"

const OnTheHouse = ({ location }) => {
	return (
		<Base>
			

			<HeroSectionComponent bgColour={'#ffffff'}> 
				<div className="inner-div wrapper hero-inner">
					<div className="title-col">
						<div className="hero-text">LoadedLabs <span style={{fontStyle: 'italic'}}>On the house</span></div>
						<div className="text" style={{marginTop: "1.6rem"}}>Free resources for you and your team.</div>
					</div>
					<div className="title-col">
						<div className="hero-title"><h1>Loaded<span style={{color: '#638cf7'}}>Labs</span></h1></div>
					</div>
				</div>
			</HeroSectionComponent>

      <PageSectionComponent>
        <div className="inner-div wrapper">

					<div className="c-columns-1 c-columns-gap-l">
						
						<Card cssClass="card bluebg">

							<div className="card-content c-columns-2 c-columns-m-1 c-columns-gap-l">
								
								<div className="text">
									<h2>Going Pro in<br />Hospitality</h2>
									<div className="">Free Online Sessions</div>
									<div className="button-wrap">
										<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
											<div className="button">
												Learn More
											</div>
										</a>
									</div>
								</div>
								
								<div className="image">
									<img src={sectionImage1} class="col-image" />
								</div>
	
							</div>

						</Card>
						
						<Card cssClass="card pinkbg">

							<div className="card-content c-columns-2 c-columns-m-1 c-columns-gap-l">
								
								<div className="text">
									<h2>Case Studies</h2>
									<div className="">Inspiring stories from real Loaded customers running thriving hospitality businesses.</div>
									<div className="button-wrap">
										<div className="button">
											<div className="play-icon"></div>	Watch Now
										</div>
									</div>
								</div>
								
								<div className="image">
									<img src={sectionImage2} class="col-image" />
								</div>
	
							</div>

						</Card>

						<Card cssClass="card offwhite">

							<div className="card-content c-columns-2 c-columns-m-1 c-columns-gap-l">
								
								<div className="text">
									<h2>Loaded Support <br />& Help Articles</h2>

									<div className="button-wrap">
										<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
											<div className="button">
												Learn More
											</div>
										</a>
									</div>
								</div>
								
								<div className="image">
									<img src={sectionImage3} class="col-image" />
								</div>
	
							</div>

						</Card>

						<Card cssClass="card yellow">

							<div className="card-content c-columns-2 c-columns-m-1 c-columns-gap-l">
								
								<div className="text">
									<h2>Loaded Support <br />& Help Articles</h2>

									<div className="button-wrap">
										<a href="https://blog.loadedhub.com/en/training-and-implementation" target="_blank" >
											<div className="button">
												Learn More
											</div>
										</a>
									</div>
								</div>
								
								<div className="image">
									<img src={sectionImage4} class="col-image" />
								</div>
	
							</div>

						</Card>

					</div>

				</div>
      </PageSectionComponent>
		</Base>
	)
}

export default OnTheHouse

const PointWrapper = styled.div`
	position: absolute;
	width: 170px;
	left: 0;
	bottom: 0;
	transform: translate(-150px,0px);

	@media ${device.MXlg} {
		display: none;
	}	
`

const Card = styled.div.attrs((props) => ({
  className: props.cssClass,
}))`
  
	position: relative;
	box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.5);
	overflow:hidden;


	& .feature {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 1.2;
    margin: 8px 0;
    padding: 8px;
    position: relative;

  }

  .tick {
    color: ${ThemeDefault.blue};
    width: 16px; 
    margin-right: 16px;
    flex-shrink: 0;
    flex-grow: 0;
  }


	& .card-content {
		
		& .feature-text {
      font-size: ${ThemeDefault.font_2};
		}
		
		& .items {
			margin-bottom: 3.2rem;
			padding: 0 1.6rem;

			@media ${device.MXmd} {
				padding: 0;	
			}
		}
		& .image {
			width: 100%;
			height: 100%;
			position: relative;

			& img {
				position: absolute;
				width: 100%;
				height: 100%;	
				object-fit: cover;
				object-position: center;
			}
		}
		& .text {
			padding: 3.2rem;
			color: ${ThemeDefault.white};

			& h1, & h2, & h3 {
				font-family: ${ThemeDefault.fontSecondary};
				font-size: ${ThemeDefault.font_7};
			}

			@media ${device.MXmd} {
				padding: 3.2rem;
			}

			@media ${device.MXsm} {
				padding: 3.2rem 1.6rem;
			}

      font-size: ${ThemeDefault.font_2};
			/* margin-bottom: 3.2rem; */

		}



	}

  .title {

		h2 {
			font-family: ${ThemeDefault.fontSecondary};
			font-size: ${ThemeDefault.font_4};
			font-weight: 400;
			letter-spacing: -1px;
			margin-bottom: 32px;
			text-align: center;
		}

	}

	.button-wrap {
		margin-top: 6.4rem;
		/* margin-bottom: 16px; */
		text-align: left;

		@media ${device.MXsm} {
			margin-top: 2.4rem;
		}

		.button {
			display: inline-block;
			padding: 8px 16px;
			background-color: transparent;
			border: 1px solid ${ThemeDefault.white};
			color: ${ThemeDefault.white};
			letter-spacing: 1px;
			font-size: ${ThemeDefault.font__2};

			& .play-icon {
				display: inline-block;
				width: 50px;
				height: 50px;
				background: ${ThemeDefault.white};
				position: relative;
				border-radius: 50%;
				margin-right: 8px;

				&:before {
					content: '';
					position: absolute;
					width: 18px;
					height: 24px;
					left: 58%;
					top: 50%;
					transform: translate( -50%, -50%);
					border-style: solid;
					border-color: transparent transparent transparent #202020;
					border-width: 12px 0px 12px 18px;
				}
			}

		}
	}

	&.bluebg {
		background: #638cf7;

		& .image {
			@media ${device.MXsm} {
				padding: 0;
			}
			& img {
				@media ${device.MXsm} {
					position: relative;
					object-fit: contain;
				}
			}
		}

		& .text {
			color: ${ThemeDefault.white};
			@media ${device.MXsm} {
				padding: 2.4rem;
			}
		}
		.button-wrap {
			.button {
				border: 1px solid ${ThemeDefault.white};
			}
		}
	}
	&.pinkbg {
		background: #E6A9D7;

		& .image {
			padding-right: 2.4rem;
			@media ${device.MXsm} {
				padding: 0 2.4rem;
			}
			& img {
				position: relative;
				object-fit: contain;
			}
		}

		& .text {
			color: ${ThemeDefault.black};
			@media ${device.MXsm} {
				padding: 2.4rem;
			}
		}

		.button-wrap {
			.button {
				border: transparent;
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				color: ${ThemeDefault.black};
				
				& .play-icon {
					width: 32px;
					height: 32px;
					background: transparent;

					&:before {
						content: '';
						position: absolute;
						width: 18px;
						height: 24px;
						left: 58%;
						top: 50%;
						transform: translate( -50%, -50%);
						border-style: solid;
						border-color: transparent transparent transparent ${ThemeDefault.black};
						border-width: 12px 0px 12px 18px;
					}
				}

			}
		}
	}

	&.offwhite {
		background: ${ThemeDefault.offWhite};

		& .image {
			@media ${device.MXsm} {
				padding: 0	
			}
			& img {
				position: relative;
				object-fit: contain;
			}
		}
		& .text {
			color: ${ThemeDefault.black};
			@media ${device.MXsm} {
				padding: 2.4rem;
			}
		}
		.button-wrap {
			.button {
				border: 1px solid ${ThemeDefault.black};
				color: ${ThemeDefault.black};

			}
		}
	}

	&.yellow {
		background: #EBDAB4;

		& .text {
			color: ${ThemeDefault.black};
			@media ${device.MXsm} {
				padding: 2.4rem;
			}
		}
		& .image {
			@media ${device.MXmd} {
				padding: 0;
			}
			& img {
				@media ${device.MXsm} {
					position: relative;
					object-fit: contain;
				}
			}
		}
		.button-wrap {
			.button {
				border: 1px solid ${ThemeDefault.black};
				color: ${ThemeDefault.black};

			}
		}
	}

` 

const PageSectionComponent = styled.section.attrs((props) => ({
  className: props.cssClass,
}))`

	& .inner-div {
		padding-top: 0 !important;
	}

	& .col {

		& h2 {
			text-align: center;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.black};
		}

		&.light{

		}
		&.dark{

		}

	}

`

const HeroSectionComponent = styled.section.attrs(props => ({
  className: props.cssClass,
}))`
  
  background-color: ${(props) => props.bgColour};
  margin-top: 4px;

  & .hero-inner {
    
		
		&.inner-div {
			padding: 0 !important;
      padding-bottom: 0 !important;
			
      & .gatsby-image-wrapper {
				height: 100%;
      }
    }
		
    flex-direction: row;
    display: flex;
		align-items: center;
    width: 100%;

    @media ${device.MXsm} {
      flex-direction: column;
      align-items: flex-start;
    }

    h1 {
      font-size: ${ThemeDefault.font_6};
      line-height: 1.2;
      letter-spacing: -1px;
      margin-bottom: 16px;
      text-align: left;
      font-weight: 500;

      p {
        margin-bottom: 20px;
      }
    }

    &>div{
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .spacer {
      margin-bottom: 64px;

      @media ${device.MXmd} {
        margin-bottom: 32px;
      }
    }

    .title-col {
      padding-top: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      padding-left: calc(24px + 24px);
      padding-right: calc(24px + 24px);
      padding-bottom: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px);
      width: 50%;

      @media ${device.MXmd} {
        padding: calc(clamp(3.2rem,1.28rem + 4vw,6.4rem) + 24px) 24px;
        width: 60%;
      }
      @media ${device.MXsm} {
        width: 100%;
      }
    }

    .hero-title {
			& h1 {
				font-family: ${ThemeDefault.fontPrimary};
				color: ${ThemeDefault.black};
				margin: 0;
				font-size: 7rem;
				letter-spacing: -6px;
			}
		}

    .hero-text {
      text-align: left;
      font-size: var(--font-3);
      padding-right: 64px;
			font-family: ${ThemeDefault.fontSecondary};
			color: ${ThemeDefault.black};

      @media ${device.MXmd} {
        padding-right: 32px;
      }
      @media ${device.MXsm} {
        padding-right: 0;
      }      
    }

    .button-text {
      text-align: left;
      font-weight: 700;
      color: ${ThemeDefault.black};
      /* color: ${ThemeDefault.blue}; */

      @media ${device.MXmd} {
        width: 100%;
        margin-top: 8px;
      }
    }

    .hero-button-row {
      flex-direction: row;
      display: flex;
      align-items: center;
      
      @media ${device.MXmd} {
        flex-wrap: wrap;
      }
      
      p {
        margin: 0;
      }
      
    }

    .image-col {
      width: 50%;
      padding-top: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 0px;
      text-align: left;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      
			& picture,
			& img {
				object-fit: ${props => props.imageColContain ? 'contain !important' : 'cover !important'}
			}

      @media ${device.MXmd} {
        width: 40%;
      }

      @media ${device.MXsm} {
        width: 100%;
      }

      @media ${device.MXxs} {
        width: 100%;
      }
      
    }

  }
`